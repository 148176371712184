import listings from './listings';
import users from './users';
import chat from './chat';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  listings,
  users,
  chat,
};
