import React, { useEffect } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

const DeploymentLogger = () => {
  const intl = useIntl();

  useEffect(() => {
    const deploymentTime = process.env.REACT_APP_LAST_DEPLOY_DATE;

    if (deploymentTime) {
      const timeInterval = moment(deploymentTime).fromNow();
      console.log(intl.formatMessage({ id: 'DeploymentLogger.message' }, { timeInterval }));
    } else {
      console.log('Deployment time not available.');
    }
  }, []);

  return null;
};

export default DeploymentLogger;
