import React, { Component } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import loadable from '@loadable/component';

import css from './RichTextEditor.module.css';

const RichTextEditor = loadable(() => import('../../components/RichTextEditor/RichTextEditor'), {
  ssr: false,
});

const FieldRichTextEditorComponent = props => {
  const {
    className,
    id,
    label,
    input,
    meta,
    onUnmount,
    editorRef,
    hideErrorMessage,
    onFocus,
    isDefaultContent,
    ...rest
  } = props;

  const { invalid, touched, error } = meta;

  const hasError = !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error };

  const classes = classNames(css.root, className);

  const inputClasses = classNames({
    [css.error]: hasError,
  });

  const inputProps = {
    className: inputClasses,
    id,
    editorRef,
    isDefaultContent,
    ...input,
    ...rest,
  };

  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <RichTextEditor {...inputProps} onFocus={onFocus} />
      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

class FieldRichTextEditor extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldRichTextEditorComponent} {...this.props} />;
  }
}

export default FieldRichTextEditor;
