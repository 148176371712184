export const cleanInvalidValuesFromFormValues = (formValues, formErrors) => {
  const copyOfFormValues = JSON.parse(JSON.stringify(formValues));

  Object.keys(formErrors).forEach(errorField => {
    delete copyOfFormValues[errorField];
  });

  return copyOfFormValues;
};

export const extractWishlistListingData = listing => {
  if (!listing) return null;

  const { id, attributes, author, images } = listing;

  const { title, price, publicData } = attributes;

  const { category, tagline } = publicData || {};

  const { displayName } = author?.attributes?.profile || {};

  const imageUrl = images?.[0]?.attributes?.variants?.['listing-card']?.url;

  return {
    id: id.uuid,
    title,
    price: price.amount,
    currency: price.currency,
    category,
    tagline,
    userDisplayName: displayName,
    imageUrl,
  };
};

export const getOptionLabelForKey = (listingFields, fieldKey, optionKey) => {
  const fieldData = listingFields.find(field => field.key === fieldKey);

  if (!fieldData) {
    return null;
  }

  const options = fieldData.enumOptions?.flatMap(group => group.options);
  const foundOption = options?.find(option => option.option === optionKey);

  return foundOption ? foundOption.label : null;
};

export const TAX_ID_STATUS = {
  PENDING: 'pending',
  VERIFIED: 'verified',
  UNAVAILABLE: 'unavailable',
  UNVERIFIED: 'unverified',
};
