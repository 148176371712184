import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  NamedLink,
  Carousel,
  IconVerified,
  AvatarSmall,
  PrimaryButton,
  IconHeart,
} from '../../components';

import css from './ListingCard.module.css';
import { listingFields } from '../../config/configListing';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToWishlist,
  getCurrentUserWishlist,
  isListingInWishlist,
  removeFromWishlist,
} from '../../ducks/user.duck';
import { getOptionLabelForKey } from '../../util/listing';
import { truncateString } from '../../util/string';
import { DISPLAY_NAME_LENGTH_SHORT } from '../../util/validators';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {publicData?.isPromo ? (
          <FormattedMessage id="ListingCard.promo" />
        ) : price?.amount === 0 ? (
          <FormattedMessage id="ListingCard.free" />
        ) : (
          formattedPrice
        )}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const dispatch = useDispatch();

  const { currentUser, addRemoveWishlistInProgressIds } = useSelector(state => state.user);

  const { className, rootClassName, intl, listing, renderSizes } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const isVerified = listing.attributes.metadata.isVerified;
  const category = listing.attributes.publicData.category;
  const tagline = listing.attributes.publicData.tagline;
  const authorName = truncateString(
    author.attributes.profile.displayName,
    DISPLAY_NAME_LENGTH_SHORT
  );

  const isInWishlist = isListingInWishlist(getCurrentUserWishlist(currentUser), id);

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.carouselContainer}>
        <Carousel images={listing.images} renderSizes={renderSizes} />
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
      </div>
      <div className={css.info}>
        <div className={css.authorAndCategory}>
          <div className={css.authorInfo}>
            <AvatarSmall user={author} disableProfileLink className={css.avatar} />
            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            {isVerified && <IconVerified />}
          </div>
          {category && (
            <div className={css.category}>
              {getOptionLabelForKey(listingFields, 'category', category)}
            </div>
          )}
        </div>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <div className={css.tagline}>{tagline}</div>

        {!!currentUser && !publicData?.isPromo && (
          <div className={css.wishlist}>
            <PrimaryButton
              type="button"
              className={classNames(css.wishlistCta, { [css.inWishlist]: isInWishlist })}
              inProgress={addRemoveWishlistInProgressIds?.includes(id)}
              disabled={
                addRemoveWishlistInProgressIds?.includes(id) ||
                currentUser.id?.uuid === author.id?.uuid
              }
              spinnerClassName={css.wishlistSpinner}
              onClick={e => {
                e.preventDefault();

                if (isInWishlist) {
                  dispatch(removeFromWishlist([id], author.id?.uuid));
                } else {
                  dispatch(addToWishlist(listing));
                }
              }}
            >
              <IconHeart className={css.heartIcon} filled={isInWishlist} />
              {isInWishlist
                ? intl.formatMessage({ id: 'General.onWishlist' })
                : intl.formatMessage({ id: 'General.addToWishlist' })}
            </PrimaryButton>
          </div>
        )}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  renderSizes: string,
};

export default injectIntl(ListingCardComponent);
