import React from 'react';
import { Field } from 'react-final-form';
import Dropdown from './Dropdown/Dropdown';
import ValidationError from '../ValidationError/ValidationError';
import { arrayOf, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';

import css from './FieldDropdownInput.module.css';

const FieldDropdownComponent = props => {
  const {
    input,
    id,
    label,
    options,
    meta,
    placeholder,
    className,
    asideFromLabel,
    groupedOptions,
  } = props;
  const { invalid, touched, error } = meta;
  const { value, ...restInputProps } = input;

  const hasError = !!(touched && invalid && error);

  return (
    <div className={className}>
      {label ? (
        <div className={css.labelWrapper}>
          <label htmlFor={id}>{label}</label> {asideFromLabel}
        </div>
      ) : null}
      <Dropdown
        id={id}
        options={options}
        placeholder={placeholder}
        {...restInputProps}
        value={!value ? [] : value}
        dropdownInputClassName={classNames({ [css.inputError]: hasError })}
        groupedOptions={groupedOptions}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldDropdownComponent.propTypes = {
  label: string,
  id: string,
  className: string,
  input: object.isRequired,
  meta: object.isRequired,
  asideFromLabel: node,
  options: arrayOf(
    shape({
      label: oneOfType([string, node]).isRequired,
      key: oneOfType([string, arrayOf(string)]).isRequired,
    })
  ),
  groupedOptions: arrayOf(
    shape({
      groupLabel: string,
      options: arrayOf(
        shape({
          label: oneOfType([string, node]),
          key: oneOfType([string, arrayOf(string)]),
        })
      ),
    })
  ),
};

const FieldDropdownInput = props => {
  const { ...rest } = props;

  return <Field component={FieldDropdownComponent} {...rest} />;
};

export default FieldDropdownInput;
