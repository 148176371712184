import React from 'react';
import { Field } from 'react-final-form';
import { object, string } from 'prop-types';
import YoutubeLinkImport from './YoutubeLinkImport';

const FieldYoutubeLinkImportComponent = props => {
  const { input, label, meta, id, className, ...rest } = props;
  const { invalid, touched, error } = meta;
  const { value, ...restInputProps } = input;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);

  return (
    <div className={className}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <YoutubeLinkImport
        id={id}
        value={!value ? [] : value}
        {...restInputProps}
        {...rest}
        errorMessage={hasError ? error : null}
      />
    </div>
  );
};

FieldYoutubeLinkImportComponent.propTypes = {
  label: string,
  input: object.isRequired,
  meta: object.isRequired,
  className: string,
  id: string,
};

const FieldYoutubeLinkImport = props => {
  const { ...rest } = props;

  return <Field component={FieldYoutubeLinkImportComponent} {...rest} />;
};

export default FieldYoutubeLinkImport;
