import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconArrowCollapse = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="12"
      height="12"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path d="M17.707 5.707l-8 8a1 1 0 0 1-1.414 0l-8-8A1 1 0 0 1 1 4h16a1 1 0 0 1 .924.617A.97.97 0 0 1 18 5a1 1 0 0 1-.293.707z"></path>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconArrowCollapse.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowCollapse.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowCollapse;
