import React, { useState, useEffect, useRef } from 'react';
import css from './Carousel.module.css';
import { AspectRatioWrapper, IconChevron, ResponsiveImage } from '../../components';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const Carousel = ({ images, renderSizes }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 2000); // Change image every 2 seconds
  };

  const stopAutoSlide = () => {
    clearInterval(intervalRef.current);
  };

  const handleDotClick = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentIndex(index);
  };

  const handlePrevClick = event => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = event => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    return () => {
      stopAutoSlide();
    };
  }, []);

  return (
    <div className={css.carouselContainer}>
      <div
        className={css.carouselWrapper}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        onMouseEnter={startAutoSlide}
        onMouseLeave={stopAutoSlide}
      >
        {images.map((image, index) => {
          const variants = image
            ? Object.keys(image?.attributes?.variants).filter(k => k.startsWith('listing-card'))
            : [];
          return (
            <AspectRatioWrapper key={index} className={css.carouselItem} width={1} height={0.5}>
              <LazyImage
                alt={`Slide ${index}`}
                image={image}
                variants={variants}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
          );
        })}
      </div>

      {images.length > 1 && (
        <div className={css.controls}>
          <button className={css.prevButton} onClick={handlePrevClick}>
            <IconChevron className={css.prevIcon} />
          </button>
          <button className={css.nextButton} onClick={handleNextClick}>
            <IconChevron className={css.nextIcon} />
          </button>
        </div>
      )}

      {images.length > 1 && (
        <div className={css.dotsContainer}>
          {images.map((_, index) => (
            <div
              key={index}
              className={`${css.dot} ${currentIndex === index ? css.activeDot : ''}`}
              onClick={event => handleDotClick(event, index)}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
