// src/components/PriceLabel/PriceLabel.js

import React from 'react';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { string } from 'prop-types';

import css from './PriceLabel.module.css';
import appSettings from '../../config/settings';
import classNames from 'classnames';

const PriceLabel = ({ className, label, price, marketplaceCurrency, intl }) => {
  const ensureDotSeparator = num => parseFloat(num).toFixed(2);

  return (
    <>
      <div className={classNames(className, css.field)}>
        <p className={css.label}>{label}</p>
        <p className={css.price}>
          {intl.formatNumber(
            ensureDotSeparator(price),
            appSettings.getCurrencyFormatting(marketplaceCurrency)
          )}
        </p>
      </div>
    </>
  );
};

PriceLabel.propTypes = {
  classNames: string,
  label: string.isRequired,
  price: string.isRequired,
  marketplaceCurrency: string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(PriceLabel);
