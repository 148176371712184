import React, { useMemo, useState } from 'react';
import { YoutubeEmbed } from '../../containers/PageBuilder/Primitives/YoutubeEmbed';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { getYoutubeIdFromURL } from '../../util/urlHelpers';
import { arrayOf, func, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import IconClose from '../IconClose/IconClose';
import { validYouTubeUrl } from '../../util/validators';

import css from './YoutubeLinkImport.module.css';

const YoutubeLinkImportComponent = props => {
  const {
    intl,
    value,
    onChange,
    onFocus,
    onBlur,
    className,
    inputClassName,
    name,
    id,
    errorMessage,
    placeholder,
    multiple,
    additionalYTQuery,
  } = props;
  const [currentVideoLink, setCurrentVideoLink] = useState('');

  const urlInvalid = useMemo(
    () =>
      currentVideoLink &&
      validYouTubeUrl(
        intl.formatMessage({
          id: 'General.ytLinkInvalid',
        })
      )(currentVideoLink),
    [currentVideoLink, intl]
  );

  const handlePreviewYTVideo = () => {
    if (currentVideoLink && !value.includes(currentVideoLink) && !urlInvalid) {
      onChange(multiple ? [...value, currentVideoLink] : [currentVideoLink]);

      if (multiple) setCurrentVideoLink('');
    }
  };

  return (
    <div className={classNames(css.root, className)}>
      <div>
        <input
          id={id}
          name={name}
          type="text"
          placeholder={placeholder}
          value={currentVideoLink}
          onChange={e => setCurrentVideoLink(e.target.value)}
          onFocus={onFocus}
          onBlur={e => {
            onBlur(e);
            handlePreviewYTVideo();
          }}
          className={classNames(inputClassName, {
            [css.inputError]: errorMessage || urlInvalid,
          })}
        />

        {(urlInvalid || errorMessage) && (
          <div className={css.invalidUrl}>{urlInvalid || errorMessage}</div>
        )}
      </div>

      {value.map(val => (
        <div key={val} className={css.videos}>
          <div className={css.videoWrapper}>
            <button
              className={css.closeBtn}
              type="button"
              onClick={() => {
                onChange(value.filter(v => v !== val));
                setCurrentVideoLink('');
              }}
            >
              <IconClose />
            </button>

            <YoutubeEmbed
              youtubeVideoId={getYoutubeIdFromURL(val)}
              additionalYTQuery={additionalYTQuery}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

YoutubeLinkImportComponent.propTypes = {
  id: string,
  name: string,
  value: arrayOf(string),
  onChange: func,
  onFocus: func,
  onBlur: func,
  className: string,
  inputClassName: string,
  intl: intlShape.isRequired,
  errorMessage: string,
};

const YoutubeLinkImport = compose(injectIntl)(YoutubeLinkImportComponent);

export default YoutubeLinkImport;
