import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';

const FormPrompt = ({ hasUnsavedChanges }) => {
  const intl = useIntl();

  useEffect(() => {
    const onBeforeUnload = e => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const onLocationChange = useCallback(
    location => {
      return intl.formatMessage({ id: 'General.unsavedChangesPrompt' });
    },
    [intl]
  );

  return <Prompt when={hasUnsavedChanges} message={onLocationChange} />;
};

export default FormPrompt;
