import classNames from 'classnames';
import React from 'react';

import css from './IconChat.module.css';

const IconChat = props => {
  const { className, ...rest } = props;

  const classes = classNames(css.root, className);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      className={classes}
      {...rest}
    >
      <g
        clipPath="url(#icon-chat)"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m4.5 12.5-4 1 1-3v-9a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v10a1.0001 1.0001 0 0 1-1 1h-8ZM4.5 5h6M4.5 8h4" />
      </g>
      <defs>
        <clipPath id="icon-chat">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconChat;
