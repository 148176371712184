import httpClient from '../httpClient';

const uploadCoverPhoto = file => {
  const formData = new FormData();
  formData.append('file', file);

  return httpClient.post('users/cover-image', formData);
};

const setTaxID = (taxId, taxType) => {
  return httpClient.post('users/tax-id', {
    taxId,
    taxType,
  });
};

const getTaxIDStatus = () => {
  return httpClient.get('users/check-tax');
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadCoverPhoto,
  setTaxID,
  getTaxIDStatus,
};
