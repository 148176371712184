import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CART, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemCartListings = props => {
  const { intl, lineItems, cartListings } = props;

  const items = lineItems?.filter(item => item.code.startsWith(LINE_ITEM_CART) && !item.reversal);

  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const { code, lineTotal } = item;

        const listingIdFromCode = code.replace(`${LINE_ITEM_CART}-`, '');

        const listing = cartListings?.find(l => l.id === listingIdFromCode) || {};

        const formattedTotal = formatMoney(intl, lineTotal);

        return (
          <div key={`${listingIdFromCode}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{listing.title}</span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemCartListings.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCartListings;
