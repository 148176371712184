import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import { ExternalLink, NamedLink } from '../../components';
import { getUserBusinessId } from '../../ducks/user.duck';

import css from './ModalMissingInformation.module.css';

const BusinessIDReminder = props => {
  const { className, user } = props;

  const businessId = getUserBusinessId(user);

  return (
    <div className={className}>
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.businessIDTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.businessIDText" values={{ id: businessId }} />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id="ModalMissingInformation.goToBusinessID"
          values={{
            link: (
              <NamedLink name="ProfileSettingsPage">
                <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
              </NamedLink>
            ),
            contactUs: (
              <ExternalLink href="https://www.spx.graphics/contact">
                <FormattedMessage id="General.contactUs" />
              </ExternalLink>
            ),
          }}
        />
      </p>

      <div className={css.bottomWrapper}>
        <p className={css.helperText}>
          <FormattedMessage id="ModalMissingInformation.businessIDNote" />
        </p>
      </div>
    </div>
  );
};

export default BusinessIDReminder;
