import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconChevron = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 24 24"
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          d="M9 6L15 12L9 18"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        ></path>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconChevron.defaultProps = {
  className: null,
  rootClassName: null,
};

IconChevron.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconChevron;
