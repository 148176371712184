import React from 'react';

import css from './IconHeart.module.css';
import classNames from 'classnames';

const IconHeart = props => {
  const { filled, className, ...rest } = props;

  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(css.root, { [css.filled]: filled }, className)}
      {...rest}
    >
      <path
        d="M7.0041 11.3826L1.52973 6.42397C-1.44548 3.44876 2.92807 -2.26363 7.0041 2.35785C11.0801 -2.26363 15.4339 3.4686 12.4785 6.42397L7.0041 11.3826Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconHeart;
