/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, IconSpinner, IconRemove } from '../../components';
import Draggable from './Draggable';
import ListingImage from '../../containers/EditListingPage/EditListingWizard/EditListingPhotosPanel/ListingImage';

import css from './AddImages.module.css';

const ThumbnailWrapper = props => {
  const {
    className,
    image,
    savedImageAltText,
    onRemoveImage,
    aspectHeight,
    aspectWidth,
    variantPrefix,
  } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveImage(image.id);
  };

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image?.imageId ? (
      <button onClick={handleRemoveClick} type="button" className={css.removeImage}>
        <IconRemove />
      </button>
    ) : null;
    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image?.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
        aspectHeight={aspectHeight}
        aspectWidth={aspectWidth}
      >
        {removeButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);

    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ListingImage
              image={image}
              key={image?.id?.uuid || image?.id}
              className={css.thumbnail}
              savedImageAltText={savedImageAltText}
              onRemoveImage={() => onRemoveImage(image?.id)}
              aspectWidth={aspectWidth}
              aspectHeight={aspectHeight}
              variantPrefix={variantPrefix}
            />
          </div>
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onDrop,
    aspectWidth,
    aspectHeight,
    variantPrefix,
  } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <Draggable onDrop={onDrop}>
        {images.map((image, index) => {
          return (
            <ThumbnailWrapper
              image={image}
              index={index}
              key={image?.id?.uuid || image?.id}
              className={thumbnailClassName}
              savedImageAltText={savedImageAltText}
              onRemoveImage={onRemoveImage}
              aspectWidth={aspectWidth}
              aspectHeight={aspectHeight}
              variantPrefix={variantPrefix}
            />
          );
        })}
      </Draggable>
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
