import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Intercom, { shutdown } from '@intercom/messenger-js-sdk';
import bus, { AuthEventTypes } from '../modules/bus';
import { useSelector } from 'react-redux';
import api from '../api';
import { useLocation } from 'react-router-dom';
import { matchPathname } from '../util/routes';
import { useRouteConfiguration } from '../context/routeConfigurationContext';
import { IconChat } from '../components';

const INTERCOM_APP_ID = 'b6ldtfjo';

const ROUTE_NAMES_FOR_MANUAL_TRIGGER = [
  'ListingPage',
  'ListingPageVariant',
  'OrderDetailsPage',
  'SaleDetailsPage',
];

const CUSTOM_TRIGGER_ID = 'intercom-custom-trigger';

const MAX_MOBILE_SCREEN_WIDTH = 1023;

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.props.portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.props.portalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const useChat = () => {
  const location = useLocation();
  const routes = useRouteConfiguration();

  const [isMobile, setIsMobile] = useState(false);
  const [portalRoot, setPortalRoot] = useState();

  const { currentUser } = useSelector(state => state.user);

  const [userChatHash, setUserChatHash] = useState(null);

  const currentUserId = currentUser?.id?.uuid;

  const matchedRoute = matchPathname(location.pathname, routes);

  const { name: matchedRouteName } = matchedRoute?.[0]?.route || {};

  const useCustomTrigger = isMobile && ROUTE_NAMES_FOR_MANUAL_TRIGGER.includes(matchedRouteName);

  useEffect(() => {
    // set initial value
    const mediaQueryList = window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`);
    setIsMobile(mediaQueryList.matches);

    //watch for updates
    function updateIsMobile(e) {
      setIsMobile(e.matches);
    }
    mediaQueryList.addEventListener('change', updateIsMobile);

    // clean up after ourselves
    return function cleanup() {
      mediaQueryList.removeEventListener('change', updateIsMobile);
    };
  }, []);

  useEffect(() => {
    if (!currentUserId || !userChatHash || !useCustomTrigger) return;

    setPortalRoot(document.getElementById('portal-root'));
  }, [currentUserId, useCustomTrigger, userChatHash]);

  useEffect(() => {
    if (!currentUserId) return;

    const getUserHash = async () => {
      try {
        const { data } = await api.chat.getUserChatHash();

        setUserChatHash(data.userHash);
      } catch (error) {
        console.error(error);
      }
    };

    getUserHash();
  }, [currentUserId]);

  useEffect(() => {
    const clearChat = () => {
      shutdown();
      setUserChatHash(null);
    };

    const removeLogoutListener = bus.addEventListener(AuthEventTypes.LOGOUT, clearChat);

    return () => removeLogoutListener();
  }, []);

  useEffect(() => {
    if (!currentUserId || !userChatHash) return;

    const { createdAt, email, profile } = currentUser?.attributes || {};

    const { displayName } = profile || {};

    Intercom({
      app_id: INTERCOM_APP_ID,
      user_hash: userChatHash,
      hide_default_launcher: useCustomTrigger,
      custom_launcher_selector: useCustomTrigger ? `#${CUSTOM_TRIGGER_ID}` : undefined,
      user_id: currentUser.id.uuid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: displayName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      created_at: new Date(createdAt).getTime() / 1000, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId, userChatHash, useCustomTrigger]);

  if (!portalRoot || !useCustomTrigger) return null;

  return (
    <Portal portalRoot={portalRoot}>
      <button
        type="button"
        id={CUSTOM_TRIGGER_ID}
        onClick={e => {
          e.preventDefault();
        }}
        className="intercom-custom-trigger"
      >
        <IconChat />
      </button>
    </Portal>
  );
};

export default useChat;
