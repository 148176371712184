import httpClient from '../httpClient';

const uploadListingDescFile = (listingId, file) => {
  const formData = new FormData();

  formData.append('file', file);

  return httpClient.post(`listings/${listingId}/description/files`, formData);
};

const getListingProductFileURL = listingId => {
  return httpClient.get(`listings/${listingId}/product-file`);
};

const getListingFileUploadURL = (listingId, file) => {
  return httpClient.get(`listings/${listingId}/product-file/presigned-url`, {
    params: {
      fileName: file.name,
      mimeType: file.type || 'application/zip',
    },
  });
};

const confirmListingFileUpload = (listingId, fileKey) => {
  return httpClient.post(
    `listings/${listingId}/confirm-product-file`,
    {},
    {
      params: {
        fileKey,
      },
    }
  );
};

const getListingRequestsByUser = (page = 1) => {
  return httpClient.get('listings/requests', {
    params: {
      page: page,
    },
  });
};

const getListingRequestsByOperator = (tab, page = 1) => {
  return httpClient.get('listings/requests/admin', {
    params: {
      tab,
      page,
    },
  });
};

const createProductRequest = data => {
  return httpClient.post('listings/requests', data);
};

const rejectListingRequest = (requestId, data) => {
  return httpClient.post(`listings/requests/${requestId}/reject`, data);
};

const acceptListingRequest = (requestId, data) => {
  return httpClient.post(`listings/requests/${requestId}/accept`, data);
};

const updateRequestStatus = (requestId, data) => {
  return httpClient.post(`listings/requests/${requestId}/status`, data);
};

const deleteListingRequest = requestId => {
  return httpClient.delete(`listings/requests/${requestId}`);
};

const updateRequestVotes = (requestId, data) => {
  return httpClient.put(`listings/requests/${requestId}/votes`, data);
};

const applyForProductRequest = (requestId, data) => {
  return httpClient.post(`listings/requests/${requestId}/apply`, data);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadListingDescFile,
  getListingProductFileURL,
  getListingFileUploadURL,
  confirmListingFileUpload,
  createProductRequest,
  getListingRequestsByUser,
  getListingRequestsByOperator,
  rejectListingRequest,
  acceptListingRequest,
  updateRequestStatus,
  deleteListingRequest,
  updateRequestVotes,
  applyForProductRequest,
};
