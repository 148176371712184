import React, { useState } from 'react';
import { FieldTextInput, InlineTextButton, IconEyeOpen, IconEyeClosed } from '../../components';
import classNames from 'classnames';

import css from './FieldPasswordInput.module.css';

const FieldPasswordInput = props => {
  const [showPassword, setShowPassword] = useState(false);

  const { id, name, autoComplete, label, placeholder, validate, isVisibilityButtonVisible } = props;

  return (
    <FieldTextInput
      className={css.password}
      type={showPassword ? 'text' : 'password'}
      id={id}
      name={name}
      autoComplete={autoComplete}
      label={label}
      placeholder={placeholder}
      validate={validate}
      suffix={
        <InlineTextButton
          onClick={() => setShowPassword(!showPassword)}
          className={classNames(
            css.togglePasswordButton,
            classNames(css.suffixContainer, { [css.suffixVisible]: isVisibilityButtonVisible })
          )}
          title={showPassword ? 'Hide password' : 'Show password'}
          type="button"
        >
          {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
        </InlineTextButton>
      }
    />
  );
};

export default FieldPasswordInput;
