/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        groupLabel: 'Templates',
        options: [
          { option: 'templates-spx-graphics', label: 'SPX Graphics' },
          { option: 'templates-spx-for-zoom', label: 'SPX for Zoom' },
          { option: 'templates-live-os', label: 'LiveOS' },
          { option: 'templates-caspar-cg', label: 'CasparCG' },
        ],
      },
      {
        groupLabel: 'Addons',
        options: [
          { option: 'addons-spx-plugin', label: 'SPX Plugin' },
          { option: 'addons-caspar-cg', label: 'CasparCG' },
          { option: 'addons-live-os', label: 'LiveOS' },
        ],
      },
      {
        groupLabel: 'Assets',
        options: [
          { option: 'assets-image', label: 'Image' },
          { option: 'assets-video', label: 'Video' },
          { option: 'assets-3d', label: '3D' },
          { option: 'assets-lottie', label: 'Lottie' },
          { option: 'assets-font', label: 'Font' },
          { option: 'assets-data', label: 'Data' },
          { option: 'assets-project-file', label: 'Project File' },
        ],
      },
      {
        groupLabel: 'Software',
        options: [
          { option: 'software-controller', label: 'Controller' },
          { option: 'software-template-creation', label: 'Template creation' },
          { option: 'software-utility', label: 'Utility' },
          { option: 'software-dev-tools', label: 'Dev tools' },
        ],
      },
      {
        groupLabel: 'Services',
        options: [{ option: 'training', label: 'Training' }],
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Category',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      withGroups: true,
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'tags',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      {
        groupLabel: 'Topics',
        options: [
          { option: 'topics-00s', label: "00's" },
          { option: 'topics-90s', label: "90's" },
          { option: 'topics-3d', label: '3D' },
          { option: 'topics-adult', label: 'adult' },
          { option: 'topics-ae', label: 'AE' },
          { option: 'topics-alt', label: 'alt' },
          { option: 'topics-animals', label: 'animals' },
          { option: 'topics-anime', label: 'anime' },
          { option: 'topics-antiques', label: 'antiques' },
          { option: 'topics-architecture', label: 'architecture' },
          { option: 'topics-art', label: 'art' },
          { option: 'topics-arts-and-crafts', label: 'arts and crafts' },
          { option: 'topics-aviation', label: 'aviation' },
          { option: 'topics-babies', label: 'babies' },
          { option: 'topics-beauty', label: 'beauty' },
          { option: 'topics-betting', label: 'betting' },
          { option: 'topics-blend', label: 'Blend' },
          { option: 'topics-broadcast', label: 'broadcast' },
          { option: 'topics-business', label: 'business' },
          { option: 'topics-cartoon', label: 'cartoon' },
          { option: 'topics-casparcg', label: 'casparCG' },
          { option: 'topics-classic', label: 'classic' },
          { option: 'topics-code', label: 'code' },
          { option: 'topics-collectibles', label: 'collectibles' },
          { option: 'topics-collection', label: 'collection' },
          { option: 'topics-comedy', label: 'comedy' },
          { option: 'topics-comic', label: 'alt' },
          { option: 'topics-community', label: 'community' },
          { option: 'topics-computers', label: 'computers' },
          { option: 'topics-construction', label: 'construction' },
          { option: 'topics-culture', label: 'culture' },
          { option: 'topics-data', label: 'data' },
          { option: 'topics-databases', label: 'databases' },
          { option: 'topics-design', label: 'design' },
          { option: 'topics-digital-signage', label: 'digital signage' },
          { option: 'topics-dynamic', label: 'dynamic' },
          { option: 'topics-e-sports', label: 'e-sports' },
          { option: 'topics-education', label: 'education' },
          { option: 'topics-elections', label: 'elections' },
          { option: 'topics-electronics', label: 'electronics' },
          { option: 'topics-energetic', label: 'energetic' },
          { option: 'topics-entertainment', label: 'entertainment' },
          { option: 'topics-environment', label: 'environment' },
          { option: 'topics-events', label: 'events' },
          { option: 'topics-faith', label: 'faith' },
          { option: 'topics-family', label: 'family' },
          { option: 'topics-fantasy', label: 'fantasy' },
          { option: 'topics-fashion', label: 'fashion' },
          { option: 'topics-finance', label: 'finance' },
          { option: 'topics-food-and-drink', label: 'food and drink' },
          { option: 'topics-fullscreen', label: 'fullscreen' },
          { option: 'topics-fun', label: 'fun' },
          { option: 'topics-future', label: 'future' },
          { option: 'topics-javascript', label: 'javascript' },
          { option: 'topics-json', label: 'JSON' },
          { option: 'topics-gaming', label: 'gaming' },
          { option: 'topics-gltf', label: 'glTF' },
          { option: 'topics-gothic', label: 'gothic' },
          { option: 'topics-government', label: 'government' },
          { option: 'topics-graphs', label: 'graphs' },
          { option: 'topics-health', label: 'health' },
          { option: 'topics-history', label: 'history' },
          { option: 'topics-hobbies', label: 'hobbies' },
          { option: 'topics-home', label: 'home' },
          { option: 'topics-hospitality', label: 'hospitality' },
          { option: 'topics-il', label: 'IL' },
          { option: 'topics-images', label: 'images' },
          { option: 'topics-industrial', label: 'industrial' },
          { option: 'topics-intense', label: 'intense' },
          { option: 'topics-kids', label: 'kids' },
          { option: 'topics-legal', label: 'legal' },
          { option: 'topics-library', label: 'library' },
          { option: 'topics-lifestyle', label: 'lifestyle' },
          { option: 'topics-liveos', label: 'liveOS' },
          { option: 'topics-loopic', label: 'loopic' },
          { option: 'topics-literature', label: 'literature' },
          { option: 'topics-maps', label: 'maps' },
          { option: 'topics-marine', label: 'marine' },
          { option: 'topics-media', label: 'media' },
          { option: 'topics-mellow', label: 'mellow' },
          { option: 'topics-military', label: 'military' },
          { option: 'topics-modern', label: 'modern' },
          { option: 'topics-money', label: 'money' },
          { option: 'topics-movies', label: 'movies' },
          { option: 'topics-music', label: 'music' },
          { option: 'topics-nationality', label: 'nationality' },
          { option: 'topics-nature', label: 'nature' },
          { option: 'topics-news', label: 'news' },
          { option: 'topics-office', label: 'office' },
          { option: 'topics-overlay', label: 'overlay' },
          { option: 'topics-parenthood', label: 'parenthood' },
          { option: 'topics-pets', label: 'pets' },
          { option: 'topics-photography', label: 'photography' },
          { option: 'topics-png', label: 'PNG' },
          { option: 'topics-politics', label: 'politics' },
          { option: 'topics-pop', label: 'pop' },
          { option: 'topics-pr', label: 'PR' },
          { option: 'topics-presentations', label: 'presentations' },
          { option: 'topics-ps', label: 'PS' },
          { option: 'topics-random', label: 'random' },
          { option: 'topics-recipes', label: 'recipes' },
          { option: 'topics-relationships', label: 'relationships' },
          { option: 'topics-reports', label: 'reports' },
          { option: 'topics-royal', label: 'royal' },
          { option: 'topics-safety', label: 'safety' },
          { option: 'topics-school', label: 'school' },
          { option: 'topics-science', label: 'science' },
          { option: 'topics-shell', label: 'Shell' },
          { option: 'topics-shopping', label: 'shopping' },
          { option: 'topics-single-templates', label: 'single templates' },
          { option: 'topics-snippet', label: 'snippet' },
          { option: 'topics-social', label: 'social' },
          { option: 'topics-space', label: 'space' },
          { option: 'topics-spiritual', label: 'spiritual' },
          { option: 'topics-sport', label: 'sport' },
          { option: 'topics-spx', label: 'SPX' },
          { option: 'topics-streaming', label: 'streaming' },
          { option: 'topics-supernatural', label: 'supernatural' },
          { option: 'topics-technology', label: 'technology' },
          { option: 'topics-toys', label: 'toys' },
          { option: 'topics-transportation', label: 'transportation' },
          { option: 'topics-travel', label: 'travel' },
          { option: 'topics-television', label: 'television' },
          { option: 'topics-template-pack', label: 'template pack' },
          { option: 'topics-txt', label: 'TXT' },
          { option: 'topics-utility', label: 'utility' },
          { option: 'topics-videos', label: 'videos' },
          { option: 'topics-weather', label: 'weather' },
          { option: 'topics-weird', label: 'weird' },
          { option: 'topics-wellness', label: 'wellness' },
          { option: 'topics-work', label: 'work' },
        ],
      },
      {
        groupLabel: 'Style',
        options: [
          { option: 'style-advanced', label: 'advanced' },
          { option: 'style-basic', label: 'basic' },
          { option: 'style-black', label: 'black' },
          { option: 'style-black-and-white', label: 'black & white' },
          { option: 'style-blue', label: 'blue' },
          { option: 'style-bold', label: 'bold' },
          { option: 'style-classic', label: 'classic' },
          { option: 'style-clean', label: 'clean' },
          { option: 'style-colorful', label: 'colorful' },
          { option: 'style-complex', label: 'complex' },
          { option: 'style-cool', label: 'cool' },
          { option: 'style-dark', label: 'dark' },
          { option: 'style-dual-tone', label: 'dual tone' },
          { option: 'style-dynamic', label: 'dynamic' },
          { option: 'style-elegant', label: 'elegant' },
          { option: 'style-gradient', label: 'gradient' },
          { option: 'style-green', label: 'green' },
          { option: 'style-grey', label: 'grey' },
          { option: 'style-greyscale', label: 'greyscale' },
          { option: 'style-horror', label: 'horror' },
          { option: 'style-intricate', label: 'intricate' },
          { option: 'style-light', label: 'light' },
          { option: 'style-lo-fi', label: 'lo-fi' },
          { option: 'style-minimalistic', label: 'minimalistic' },
          { option: 'style-modern', label: 'modern' },
          { option: 'style-multicolor', label: 'multicolor' },
          { option: 'style-orange', label: 'orange' },
          { option: 'style-premium', label: 'premium' },
          { option: 'style-professional', label: 'professional' },
          { option: 'style-red', label: 'red' },
          { option: 'style-retro', label: 'retro' },
          { option: 'style-sci-fi', label: 'sci-fi' },
          { option: 'style-simple', label: 'simple' },
          { option: 'style-static', label: 'static' },
          { option: 'style-subtle', label: 'subtle' },
          { option: 'style-surreal', label: 'surreal' },
          { option: 'style-warm', label: 'warm' },
          { option: 'style-white', label: 'white' },
        ],
      },
    ],
    filterConfig: {
      filterType: 'SelectMultipleFilter',
      indexForSearch: true,
      label: 'Tags',
      searchMode: 'has_any',
      group: 'primary',
      withGroups: true,
    },
    showConfig: {
      label: 'Tags',
    },
    saveConfig: {
      label: 'Tags',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },
  {
    key: 'isVerified',
    scope: 'meta',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: true,
      label: 'Certified sellers only',
      group: 'secondary',
    },
    showConfig: {
      label: 'Certified sellers only',
    },
    saveConfig: {
      label: 'Certified sellers only',
      placeholderMessage: 'Toggle certified sellers...',
      isRequired: false,
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
