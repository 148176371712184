import React from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames';

const IconVerified = props => {
  const { className, rootClassName, showOutline } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.007 8.27C22.194 9.125 23 10.45 23 12c0 1.55-.806 2.876-1.993 3.73.24 1.442-.134 2.958-1.227 4.05-1.095 1.095-2.61 1.459-4.046 1.225C14.883 22.196 13.546 23 12 23c-1.55 0-2.878-.807-3.731-1.996-1.438.235-2.954-.128-4.05-1.224-1.095-1.095-1.459-2.611-1.217-4.05C1.816 14.877 1 13.551 1 12s.816-2.878 2.002-3.73c-.242-1.439.122-2.955 1.218-4.05 1.093-1.094 2.61-1.467 4.057-1.227C9.125 1.804 10.453 1 12 1c1.545 0 2.88.803 3.732 1.993 1.442-.24 2.956.135 4.048 1.227 1.093 1.092 1.468 2.608 1.227 4.05Z"
          fill="#54af3c"
          stroke={showOutline ? '#ffffff' : null}
          strokeWidth="2px"
        ></path>
        <path
          d="M16.581 8.186a1 1 0 0 1 .233 1.395l-5 7a1 1 0 0 1-1.521.126l-3-3a1 1 0 0 1 1.414-1.414l2.165 2.165 4.314-6.04a1 1 0 0 1 1.395-.232Z"
          fill="#ffffff"
        ></path>
      </g>
    </svg>
  );
};

IconVerified.defaultProps = {
  rootClassName: null,
  className: null,
  showOutline: false,
};

IconVerified.propTypes = {
  rootClassName: string,
  className: string,
  showOutline: bool,
};

export default IconVerified;
