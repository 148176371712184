import httpClient from '../httpClient';

/**
 * @returns {Object} { userHash: string, userId: string }
 */
const getUserChatHash = () => {
  return httpClient.get('chat/users-hash');
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserChatHash,
};
