import React, { useState, useRef, useEffect, useMemo } from 'react';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withViewport } from '../../util/uiHelpers';

import css from './Accordion.module.css';

const Accordion = props => {
  const {
    title,
    children,
    isOpen,
    viewport,
    rootClassName,
    childrenWrapperClassName,
    className,
  } = props;

  const [active, setActive] = useState(isOpen);
  const [height, setHeight] = useState('0px');

  const contentRef = useRef(null);

  const classes = useMemo(() => classNames(css.accordion || rootClassName, className), [
    rootClassName,
    className,
  ]);

  useEffect(() => {
    setHeight(active ? `${2 * contentRef.current.scrollHeight}px` : '0px');
  }, [children, active, viewport.width]);

  return (
    <div className={classes}>
      <button
        className={classNames(css.accordionTrigger, { [css.accordionTriggerActive]: active })}
        onClick={() => setActive(prev => !prev)}
      >
        {title}
        <IconArrowHead direction={active ? 'down' : 'right'} className={css.accordionIcon} />
      </button>
      <div ref={contentRef} style={{ maxHeight: `${height}` }} className={css.accordionContent}>
        <div className={classNames(css.accordionChildren, childrenWrapperClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};

Accordion.defaultProps = { rootClassName: null, className: null, isOpen: false };

const { node, string, bool, oneOfType, shape, number } = PropTypes;

Accordion.propTypes = {
  rootClassName: string,
  className: string,
  childrenWrapperClassName: string,
  isOpen: bool,
  title: oneOfType([string, node]).isRequired,
  children: node.isRequired,
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(Accordion);
