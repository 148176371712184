import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar, IconReviewHalfStar } from '../../components';
import { REVIEW_RATINGS } from '../../util/types';

const ReviewRating = props => {
  const { className, rootClassName, reviewStarClassName, rating } = props;
  const classes = classNames(rootClassName, className);

  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;
  const emptyStars = REVIEW_RATINGS.length - fullStars - (halfStar ? 1 : 0);

  return (
    <span className={classes} title={`${rating}/5`}>
      {Array(fullStars)
        .fill()
        .map((_, i) => (
          <IconReviewStar key={`full-${i}`} className={reviewStarClassName} isFilled={true} />
        ))}
      {halfStar && <IconReviewHalfStar key="half" className={reviewStarClassName} />}
      {Array(emptyStars)
        .fill()
        .map((_, i) => (
          <IconReviewStar key={`empty-${i}`} className={reviewStarClassName} isFilled={false} />
        ))}
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
};

const { string, number } = PropTypes;

ReviewRating.propTypes = {
  rating: number.isRequired,
  reviewStartClassName: string,
  rootClassName: string,
  className: string,
};

export default ReviewRating;
