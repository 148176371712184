import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { searchListings } from '../SearchPage/SearchPage.duck';
export const ASSET_NAME = 'landing-page';

// Action Types
const FETCH_LISTINGS_REQUEST = 'app/LandingPage/FETCH_LISTINGS_REQUEST';
const FETCH_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_LISTINGS_SUCCESS';
const FETCH_LISTINGS_ERROR = 'app/LandingPage/FETCH_LISTINGS_ERROR';

const FETCH_FAVORITE_LISTINGS_REQUEST = 'app/LandingPage/FETCH_FAVORITE_LISTINGS_REQUEST';
const FETCH_FAVORITE_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_FAVORITE_LISTINGS_SUCCESS';
const FETCH_FAVORITE_LISTINGS_ERROR = 'app/LandingPage/FETCH_FAVORITE_LISTINGS_ERROR';

const FETCH_POPULAR_LISTINGS_REQUEST = 'app/LandingPage/FETCH_POPULAR_LISTINGS_REQUEST';
const FETCH_POPULAR_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_POPULAR_LISTINGS_SUCCESS';
const FETCH_POPULAR_LISTINGS_ERROR = 'app/LandingPage/FETCH_POPULAR_LISTINGS_ERROR';

// Initial State
const initialState = {
  listings: [],
  favoriteListings: [],
  popularListings: [],
  inProgress: false,
  error: null,
};

// Reducer
export default function landingPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_LISTINGS_REQUEST:
    case FETCH_FAVORITE_LISTINGS_REQUEST:
    case FETCH_POPULAR_LISTINGS_REQUEST:
      return { ...state, inProgress: true, error: null };
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, inProgress: false, listings: action.payload };
    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return { ...state, inProgress: false, favoriteListings: action.payload };
    case FETCH_POPULAR_LISTINGS_SUCCESS:
      return { ...state, inProgress: false, popularListings: action.payload };
    case FETCH_LISTINGS_ERROR:
    case FETCH_FAVORITE_LISTINGS_ERROR:
    case FETCH_POPULAR_LISTINGS_ERROR:
      return { ...state, inProgress: false, error: action.error };
    default:
      return state;
  }
}

// Action Creators
const fetchListingsRequest = () => ({ type: FETCH_LISTINGS_REQUEST });
const fetchListingsSuccess = listings => ({ type: FETCH_LISTINGS_SUCCESS, payload: listings });
const fetchListingsError = error => ({ type: FETCH_LISTINGS_ERROR, error });

const fetchFavoriteListingsRequest = () => ({ type: FETCH_FAVORITE_LISTINGS_REQUEST });
const fetchFavoriteListingsSuccess = listings => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload: listings,
});
const fetchFavoriteListingsError = error => ({ type: FETCH_FAVORITE_LISTINGS_ERROR, error });

const fetchPopularListingsRequest = () => ({ type: FETCH_POPULAR_LISTINGS_REQUEST });
const fetchPopularListingsSuccess = listings => ({
  type: FETCH_POPULAR_LISTINGS_SUCCESS,
  payload: listings,
});
const fetchPopularListingsError = error => ({ type: FETCH_POPULAR_LISTINGS_ERROR, error });

// Thunks to fetch listings
export const fetchListings = config => async dispatch => {
  dispatch(fetchListingsRequest());
  try {
    const listings = await fetchListingsByCriteria(dispatch, config, 'createdAt');
    dispatch(fetchListingsSuccess(listings));
  } catch (error) {
    dispatch(fetchListingsError(error));
  }
};

export const fetchFavoriteListings = config => async dispatch => {
  dispatch(fetchFavoriteListingsRequest());
  try {
    const listings = await fetchListingsByCriteria(
      dispatch,
      config,
      'meta_staffFavorite,createdAt'
    );
    dispatch(fetchFavoriteListingsSuccess(listings));
  } catch (error) {
    dispatch(fetchFavoriteListingsError(error));
  }
};

export const fetchPopularListings = config => async dispatch => {
  dispatch(fetchPopularListingsRequest());
  try {
    const listings = await fetchListingsByCriteria(
      dispatch,
      config,
      'meta_popularityScore,createdAt'
    );
    dispatch(fetchPopularListingsSuccess(listings));
  } catch (error) {
    dispatch(fetchPopularListingsError(error));
  }
};

const fetchListingsByCriteria = async (dispatch, config, sortCriteria) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    perPage: 10,
    state: 'published',
    sort: sortCriteria,
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.bio'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };

  const response = await dispatch(searchListings(params, config));
  return denormalisedResponseEntities(response);
};

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
