import React, { useCallback, useState } from 'react';
import { arrayOf, func, node, string } from 'prop-types';

import classNames from 'classnames';

import css from './Draggable.module.css';

const Draggable = props => {
  const { className, onDrag, onDrop, children } = props;
  const [dragId, setDragId] = useState(null);
  const [touchStartId, setTouchStartId] = useState(null);
  const [dragging, setDragging] = useState(false);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const handleDragStart = useCallback(
    e => {
      if (e.target.classList.value.includes('removeImage')) {
        return;
      }
      const dragId = e.currentTarget.id;
      setDragId(dragId);
      setDragging(true);
      disableScroll();
      onDrag && onDrag(dragId);
    },
    [onDrag]
  );

  const handleDrop = useCallback(
    e => {
      e.preventDefault();
      const dropId = e.currentTarget.id;
      const dropIndexes = { from: dragId, to: dropId };
      onDrop(dropIndexes);
      setDragId(null);
      setDragging(false);
      enableScroll();
    },
    [dragId, onDrop]
  );

  const handleTouchStart = useCallback(
    e => {
      if (e.target.classList.value.includes('removeImage')) {
        return;
      }
      const touchStartId = e.currentTarget.id;
      setTouchStartId(touchStartId);
      setDragging(true);
      disableScroll();
      onDrag && onDrag(touchStartId);
    },
    [onDrag]
  );

  const handleTouchEnd = useCallback(
    e => {
      if (e.target.classList.value.includes('removeImage')) {
        return;
      }
      e.preventDefault();
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;
      const elements = document.elementsFromPoint(touchEndX, touchEndY);
      const dropElement = elements.find(el => el.classList.contains(css.root));

      if (dropElement && touchStartId !== null) {
        const dropId = dropElement.id;
        const dropIndexes = { from: touchStartId, to: dropId };
        onDrop(dropIndexes);
        setTouchStartId(null);
      }
      setDragging(false);
      enableScroll();
    },
    [touchStartId, onDrop]
  );

  const handleTouchMove = useCallback(e => {
    if (e.target.classList.value.includes('removeImage')) {
      return;
    }
    e.preventDefault();
  }, []);

  return React.Children.map(children, child => (
    <div
      id={child.key}
      className={classNames(css.root, className, {
        [css.dragging]: dragging && (dragId === child.key || touchStartId === child.key),
      })}
      draggable
      onDragOver={e => e.preventDefault()}
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {child}
    </div>
  ));
};

Draggable.propTypes = {
  className: string,
  onDrag: func,
  onDrop: func.isRequired,
  children: arrayOf(node).isRequired,
};

export default Draggable;
