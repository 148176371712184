import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCart.module.css';

const IconCart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="48" height="47" viewBox="0 0 48 47" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0597172 1.51493C0.327615 0.443341 1.41348 -0.20818 2.48507 0.0597172L10.4851 2.05972C11.2239 2.24444 11.7938 2.83277 11.9548 3.57717L13.3441 10H46C46.5961 10 47.161 10.2659 47.541 10.7251C47.9209 11.1844 48.0763 11.7892 47.9646 12.3747L44.7646 29.1547L44.7617 29.1696C44.4874 30.5508 43.736 31.7915 42.639 32.6745C41.5476 33.5531 40.1833 34.0219 38.7827 34H19.3773C17.9768 34.0219 16.6124 33.5531 15.521 32.6745C14.4289 31.7954 13.6793 30.5618 13.402 29.1879L8.30818 5.6386L1.51493 3.94029C0.443341 3.67239 -0.20818 2.58652 0.0597172 1.51493ZM14.2093 14L17.3148 28.3572L17.3217 28.3904C17.4131 28.8508 17.6636 29.2643 18.0292 29.5587C18.3949 29.853 18.8524 30.0094 19.3217 30.0004C19.329 30.0002 19.3362 30.0001 19.3435 30.0001C19.349 30 19.3545 30 19.36 30H38.8L38.8186 30.0001L38.8383 30.0004C39.3076 30.0094 39.7651 29.853 40.1308 29.5587C40.4947 29.2657 40.7445 28.8546 40.837 28.3968L43.5826 14H14.2093ZM39.5 44C40.8807 44 42 42.8807 42 41.5C42 40.1193 40.8807 39 39.5 39C38.1193 39 37 40.1193 37 41.5C37 42.8807 38.1193 44 39.5 44ZM39.5 47C42.5376 47 45 44.5376 45 41.5C45 38.4624 42.5376 36 39.5 36C36.4624 36 34 38.4624 34 41.5C34 44.5376 36.4624 47 39.5 47ZM22 41.5C22 42.8807 20.8807 44 19.5 44C18.1193 44 17 42.8807 17 41.5C17 40.1193 18.1193 39 19.5 39C20.8807 39 22 40.1193 22 41.5ZM25 41.5C25 44.5376 22.5376 47 19.5 47C16.4624 47 14 44.5376 14 41.5C14 38.4624 16.4624 36 19.5 36C22.5376 36 25 38.4624 25 41.5Z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconCart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCart;
