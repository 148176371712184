import appSettings from '../config/settings';
import { tokenStore } from './sdkLoader';

export function getTokenStore() {
  const store =
    typeof window === 'undefined'
      ? tokenStore.memoryStore()
      : tokenStore.browserCookieStore({
          clientId: appSettings.sdk.clientId,
          secure: appSettings.usingSSL,
        });

  const { getToken, setToken, removeToken } = store;

  return {
    getToken,
    setToken,
    removeToken,
  };
}
