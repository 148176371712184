import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, func, node, oneOfType, shape, string } from 'prop-types';
import css from './Dropdown.module.css';
import classNames from 'classnames';
import IconArrowHead from '../../IconArrowHead/IconArrowHead';
import IconCheckmark from '../../IconCheckmark/IconCheckmark';
import IconClose from '../../IconClose/IconClose';

const Dropdown = props => {
  const {
    options,
    className,
    dropdownInputClassName,
    placeholder,
    onChange,
    value,
    onFocus,
    onBlur,
    name,
    id,
    groupedOptions,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);

  const dropdownRef = useRef(null);
  const optionsRefs = useRef([]);
  const listRef = useRef(null);

  const remainingOptions = useMemo(
    () => options.filter(option => option.label.toLowerCase().includes(searchString.toLowerCase())),
    [options, searchString]
  );

  const filteredGroupedOptions = useMemo(() => {
    return groupedOptions
      .map(group => ({
        ...group,
        options: group.options.filter(option =>
          option.label.toLowerCase().includes(searchString.toLowerCase())
        ),
      }))
      .filter(group => group.options.length > 0);
  }, [groupedOptions, searchString]);

  const dropdownClasses = classNames(className, css.dropdown, { [css.dropdownOpen]: dropdownOpen });

  const chooseOption = useCallback(
    newValue => {
      typeof newValue === 'string'
        ? onChange([...value, newValue])
        : onChange([...new Set([...value, ...newValue])]);
    },
    [onChange, value]
  );

  const removeOption = useCallback(
    valueToRemove => {
      typeof newValue === 'string'
        ? onChange(value.filter(val => val !== valueToRemove))
        : onChange(value.filter(el => !valueToRemove.includes(el)));
    },
    [onChange, value]
  );

  const optionChecked = useCallback(
    option =>
      typeof option.key === 'string'
        ? !!value.find(val => val === option.key)
        : option.key.every(v => value.includes(v)),
    [value]
  );

  const handleOptionClicked = useCallback(
    option => {
      !optionChecked(option) ? chooseOption(option.key) : removeOption(option.key);
    },
    [chooseOption, optionChecked, removeOption]
  );

  const handleSearch = searhValue => {
    setSearchString(searhValue);
  };

  const getOptionByValue = value => {
    return options.find(option => option.key === value);
  };

  const scrollIntoViewIfNeeded = target => {
    if (!listRef.current) return;

    if (target.getBoundingClientRect().bottom > listRef.current?.getBoundingClientRect().bottom) {
      target.scrollIntoView(false);
    }

    if (target.getBoundingClientRect().top < listRef.current?.getBoundingClientRect().top) {
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  useEffect(() => {
    const closeDropdownOnClickOutside = e => {
      if (dropdownOpen && !dropdownRef.current?.contains(e.target)) {
        setDropdownOpen(false);
        onBlur();
      }
    };

    const keyDownCallback = e => {
      if (!dropdownOpen) return;

      let newIndex;
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          newIndex = activeIndex > 0 ? activeIndex - 1 : activeIndex;
          scrollIntoViewIfNeeded(optionsRefs.current[newIndex]);
          setActiveIndex(newIndex);
          return;
        case 'ArrowDown':
          e.preventDefault();
          newIndex = activeIndex < remainingOptions.length - 1 ? activeIndex + 1 : activeIndex;
          scrollIntoViewIfNeeded(optionsRefs.current[newIndex]);
          setActiveIndex(newIndex);
          return;
        case 'Enter':
          e.preventDefault();
          if (activeIndex < 0) return;
          handleOptionClicked(remainingOptions.find((option, index) => index === activeIndex));
          return;
        default:
          return;
      }
    };

    document.addEventListener('mousedown', closeDropdownOnClickOutside);
    document.addEventListener('keydown', keyDownCallback);
    return () => {
      document.removeEventListener('mousedown', closeDropdownOnClickOutside);
      document.removeEventListener('keydown', keyDownCallback);
    };
  }, [activeIndex, dropdownOpen, handleOptionClicked, onBlur, remainingOptions]);

  return (
    <div className={dropdownClasses} ref={dropdownRef}>
      <div className={css.dropdownTrigger}>
        <input
          id={id}
          name={name}
          className={dropdownInputClassName}
          value={searchString}
          onChange={e => handleSearch(e.target.value)}
          onFocus={e => {
            setDropdownOpen(true);
            onFocus(e);
          }}
          placeholder={placeholder}
        />
        <button
          type="button"
          className={css.openBtn}
          onClick={() => {
            if (dropdownOpen) onBlur();
            setDropdownOpen(prev => !prev);
          }}
        >
          <IconArrowHead direction="down" />
        </button>
      </div>
      {dropdownOpen && (
        <ul className={css.optionsList} ref={listRef}>
          {groupedOptions.length > 0 &&
            filteredGroupedOptions.map(group => (
              <div key={group.groupLabel}>
                <p>{group.groupLabel}</p>
                {group.options.map((option, index) => (
                  <li
                    key={option.key}
                    ref={element => (optionsRefs.current[index] = element)}
                    onClick={() => {
                      handleOptionClicked(option);
                      onBlur();
                    }}
                    className={css.option}
                    role="option"
                    aria-selected={optionChecked(option)}
                  >
                    {option.label}

                    {optionChecked(option) && (
                      <IconCheckmark size="small" className={css.checkIcon} />
                    )}
                  </li>
                ))}
              </div>
            ))}
          {groupedOptions.length === 0 &&
            remainingOptions.map((option, index) => (
              <li
                key={option.key}
                ref={element => (optionsRefs.current[index] = element)}
                onClick={() => {
                  handleOptionClicked(option);
                  onBlur();
                }}
                className={css.option}
                role="option"
                aria-selected={index === activeIndex}
                onMouseMove={() => {
                  setActiveIndex(index);
                }}
              >
                {option.label}

                {optionChecked(option) && <IconCheckmark size="small" className={css.checkIcon} />}
              </li>
            ))}
        </ul>
      )}

      {value.length > 0 && (
        <div className={css.chipsContainer}>
          {value.map(val => (
            <div
              className={classNames(css.chip, {
                [css.noIconChip]: !getOptionByValue(val)?.icon,
              })}
              key={val}
            >
              {getOptionByValue(val)?.icon && (
                <span className={css.chipIcon}>{getOptionByValue(val)?.icon}</span>
              )}

              <span className={css.chipContent}>{getOptionByValue(val)?.label}</span>
              <button
                type="button"
                className={css.closeBtn}
                onClick={() => {
                  removeOption(val);
                  onBlur();
                }}
              >
                <IconClose className={css.closeIcon} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  options: [],
  value: [],
};

Dropdown.propTypes = {
  id: string,
  className: string,
  dropdownInputClassName: string,
  placeholder: string,
  onChange: func,
  onBlur: func,
  onFocus: func,
  options: arrayOf(
    shape({
      label: oneOfType([string, node]).isRequired,
      key: oneOfType([string, arrayOf(string)]).isRequired,
    })
  ),
  value: arrayOf(string),
  groupedOptions: arrayOf(
    shape({
      groupLabel: string,
      options: arrayOf(
        shape({
          label: oneOfType([string, node]),
          key: oneOfType([string, arrayOf(string)]),
        })
      ),
    })
  ),
};

export default Dropdown;
