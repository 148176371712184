import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconCategory = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <title>category-solid</title>
        <g>
          <g>
            <rect width="48" height="48" fill="none"></rect>
          </g>
          <g>
            <path d="M24,2a2.1,2.1,0,0,0-1.7,1L13.2,17a2.3,2.3,0,0,0,0,2,1.9,1.9,0,0,0,1.7,1H33a2.1,2.1,0,0,0,1.7-1,1.8,1.8,0,0,0,0-2l-9-14A1.9,1.9,0,0,0,24,2Z"></path>
            <path d="M43,43H29a2,2,0,0,1-2-2V27a2,2,0,0,1,2-2H43a2,2,0,0,1,2,2V41A2,2,0,0,1,43,43Z"></path>
            <path d="M13,24A10,10,0,1,0,23,34,10,10,0,0,0,13,24Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconCategory.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCategory.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCategory;
