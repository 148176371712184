import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { bool, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { COUNTRY_TAX_TYPES } from '../../translations/countryCodes';
import { useIntl } from 'react-intl';
import { checkTaxObjectValidity, composeValidators } from '../../util/validators';
import { ExternalLink, SecondaryButton, ValidationError } from '..';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { createStripeCustomer } from '../../ducks/paymentMethods.duck';
import {
  fetchCurrentUser,
  getUserBusinessIDStatus,
  hasUserBoughtWithPendingBusinessID,
} from '../../ducks/user.duck';

import css from './FieldBusinessIDInput.module.css';
import { TAX_ID_STATUS } from '../../util/listing';

const MAX_CHECK_ATTEMPTS = 5;

const BusinessIDInput = props => {
  const {
    className,
    placeholder,
    id,
    name,
    disabled,
    onFocus,
    onChange,
    onBlur,
    input,
    intl,
    isCheckingVerification,
    setIsCheckingVerification,
    setVerificationError,
    setIsVerified,
    businessIdStatus,
    currentUser,
    fetchCurrentUserInProgress,
    setBusinessIdStatus,
    asyncValidateIDRef,
    dirty,
  } = props;

  const hasTriedStripeCustomerFetch = useRef(false);
  const verificationIntervalId = useRef(null);
  const maxVerificationAttemps = useRef(MAX_CHECK_ATTEMPTS);

  const dispatch = useDispatch();
  const { stripeCustomer, createStripeCustomerInProgress } = useSelector(
    state => state.paymentMethods
  );

  const hasStripeCustomer = !!(currentUser?.stripeCustomer || stripeCustomer);

  const hasBoughtWithPendingAlready = hasUserBoughtWithPendingBusinessID(currentUser);

  const invalidIDMsg = intl.formatMessage({ id: 'General.invalidID' });

  const getMessageForIDStatus = useCallback(
    businessIDStatus => {
      switch (businessIDStatus) {
        case TAX_ID_STATUS.VERIFIED:
          return null;
        case TAX_ID_STATUS.PENDING:
          return hasBoughtWithPendingAlready
            ? intl.formatMessage({ id: 'General.pendingID' })
            : null;

        case TAX_ID_STATUS.UNAVAILABLE:
          return intl.formatMessage(
            { id: 'General.taxIDUnavailable' },
            {
              contactUs: (
                <ExternalLink href="https://www.spx.graphics/contact">
                  {intl.formatMessage({ id: 'General.contactUs' })}
                </ExternalLink>
              ),
            }
          );
        case TAX_ID_STATUS.UNVERIFIED:
          return intl.formatMessage({ id: 'General.unverifiedID' });

        default:
          return null;
      }
    },
    [hasBoughtWithPendingAlready, intl]
  );

  const sendTaxIDToStripe = useCallback(
    async value => {
      setIsVerified(false);

      // Minimum TAX number is 8
      if (!value || !value.number || !value.type || value.number.length < 8) {
        setBusinessIdStatus(TAX_ID_STATUS.UNAVAILABLE);

        return invalidIDMsg;
      }

      try {
        setIsCheckingVerification(true);
        setBusinessIdStatus(TAX_ID_STATUS.PENDING);

        const { data } = await api.users.setTaxID(value.number, value.type);

        setBusinessIdStatus(data);

        return getMessageForIDStatus(data);
      } catch (error) {
        setBusinessIdStatus(TAX_ID_STATUS.UNAVAILABLE);

        return error.response?.data?.message || invalidIDMsg;
      } finally {
        setIsCheckingVerification(false);
      }
    },
    [
      getMessageForIDStatus,
      invalidIDMsg,
      setBusinessIdStatus,
      setIsCheckingVerification,
      setIsVerified,
    ]
  );

  const handleOnChange = value => {
    maxVerificationAttemps.current = MAX_CHECK_ATTEMPTS;

    input.onChange(value);
    onChange?.(value);
  };

  const handleOnFocus = event => {
    input.onFocus(event);
    onFocus?.(event);
  };

  const getTaxVerificationStatus = useCallback(async () => {
    try {
      if (maxVerificationAttemps.current <= 0) {
        setIsCheckingVerification(false);
        clearInterval(verificationIntervalId.current);

        return;
      }

      setVerificationError(null);

      maxVerificationAttemps.current = maxVerificationAttemps.current - 1;

      const { data } = await api.users.getTaxIDStatus();

      setIsVerified(data === TAX_ID_STATUS.VERIFIED);

      if (data !== TAX_ID_STATUS.PENDING) {
        clearInterval(verificationIntervalId.current);
        setIsCheckingVerification(false);
      }
    } catch (error) {
      console.error(error);
      setVerificationError(error);

      setIsVerified(false);
      setIsCheckingVerification(false);
      clearInterval(verificationIntervalId.current);
    }
  }, [setIsCheckingVerification, setIsVerified, setVerificationError]);

  const periodicallyCheckTaxStatus = useCallback(() => {
    clearInterval(verificationIntervalId.current);

    setIsCheckingVerification(true);
    setBusinessIdStatus(TAX_ID_STATUS.PENDING);

    verificationIntervalId.current = setInterval(() => {
      getTaxVerificationStatus();
    }, 1000);
  }, [getTaxVerificationStatus, setBusinessIdStatus, setIsCheckingVerification]);

  const verifyID = useCallback(
    async ignoreDirty => {
      if (!dirty && !ignoreDirty) return;

      // Mark validity as false so we don't allow user
      // to submit while the API is still checking.
      // Final-form is informed of this with 'checkTaxObjectValidity'
      input.onChange({
        ...input.value,
        number: input.value?.number?.trim(),
        error: intl.formatMessage({ id: 'General.pendingID' }),
      });

      const error = await sendTaxIDToStripe(input.value);

      // If hasBoughtWithPendingAlready is 'true' we will have an error
      if (!error || hasBoughtWithPendingAlready) {
        // We need to fetch verification status
        // at an interval, because we don't have webhooks
        periodicallyCheckTaxStatus();
      }

      input.onChange({
        ...input.value,
        error,
      });

      return error;
    },
    [dirty, hasBoughtWithPendingAlready, input, intl, periodicallyCheckTaxStatus, sendTaxIDToStripe]
  );

  const handleOnBlur = async event => {
    input.onBlur(event);

    onBlur?.(event);
  };

  useEffect(() => {
    if (!asyncValidateIDRef) return;

    asyncValidateIDRef.current = verifyID;
  }, [asyncValidateIDRef, verifyID]);

  useEffect(() => {
    if (!currentUser?.id || hasStripeCustomer || hasTriedStripeCustomerFetch.current) return;

    dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }));

    hasTriedStripeCustomerFetch.current = true;
  }, [currentUser?.id, dispatch, hasStripeCustomer]);

  useEffect(() => {
    if (businessIdStatus !== TAX_ID_STATUS.VERIFIED) return;

    setIsVerified(true);
  }, [businessIdStatus, setIsVerified]);

  useEffect(() => {
    if (!businessIdStatus) return;

    const error = getMessageForIDStatus(businessIdStatus);

    if (!error) return;

    input.onChange({
      ...input.value,
      error,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessIdStatus, getMessageForIDStatus]);

  useEffect(() => {
    if (hasStripeCustomer || createStripeCustomerInProgress) return;

    const createStripeCustomerMaybe = async () => {
      try {
        dispatch(createStripeCustomer());

        return;
      } catch (error) {}
    };

    // We need to create stripe customer (if not already created)
    // so that our BE can add the Tax ID to the customer object.
    createStripeCustomerMaybe();
  }, [createStripeCustomerInProgress, dispatch, hasStripeCustomer]);

  const inProgress =
    createStripeCustomerInProgress || isCheckingVerification || fetchCurrentUserInProgress;

  const commonProps = {
    disabled: disabled || inProgress,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
  };

  return (
    <div className={className}>
      <select
        id={`${id}.type`}
        name={`${name}.type`}
        value={input.value?.type || ''}
        onChange={e => {
          handleOnChange({ type: e.target.value, number: input.value?.number?.trim() });
        }}
        {...commonProps}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'General.idType' })}
        </option>
        {COUNTRY_TAX_TYPES.map((tax, i) => {
          return (
            <option key={tax.value + i} value={tax.value}>
              {`${tax.label} (${tax.value})`}
            </option>
          );
        })}
      </select>
      <input
        id={`${id}.number`}
        name={`${name}.number`}
        value={input.value?.number || ''}
        onChange={e => handleOnChange({ type: input.value?.type, number: e.target.value })}
        type="text"
        placeholder={placeholder}
        {...commonProps}
      />
      <SecondaryButton
        type="button"
        onClick={() => verifyID(true)}
        disabled={commonProps.disabled}
        className={css.verifyBtn}
        inProgress={inProgress}
      >
        {intl.formatMessage({ id: 'General.verify' })}
      </SecondaryButton>
    </div>
  );
};

const FieldBusinessIDInputComponent = props => {
  const {
    className,
    inputClassName,
    id,
    label,
    input,
    meta,
    hideErrorMessage,
    onChange,
    onFocus,
    intl,
    ...rest
  } = props;

  const { createStripeCustomerError } = useSelector(state => state.paymentMethods);
  const { currentUser, fetchCurrentUserInProgress } = useSelector(state => state.user);

  const [verificationError, setVerificationError] = useState(null);
  const [isCheckingVerification, setIsCheckingVerification] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [businessIdStatus, setBusinessIdStatus] = useState();

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const inputClasses = classNames(
    css.input,
    {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    },
    inputClassName
  );

  const inputProps = {
    className: inputClasses,
    id,
    input,
    onFocus,
    onChange,
    intl,
    ...rest,
    isCheckingVerification,
    setIsCheckingVerification,
    setVerificationError,
    isVerified,
    setIsVerified,
    businessIdStatus,
    setBusinessIdStatus,
    currentUser,
    fetchCurrentUserInProgress,
    dirty: meta.dirty,
  };

  const classes = classNames(className);

  const isStatusFailed =
    verificationError || businessIdStatus === TAX_ID_STATUS.UNAVAILABLE || error;
  const isStatusVerified = isVerified || businessIdStatus === TAX_ID_STATUS.VERIFIED;
  const isStatusProcessing = isCheckingVerification || businessIdStatus === TAX_ID_STATUS.PENDING;

  const status = useMemo(() => {
    if (isStatusProcessing) return intl.formatMessage({ id: 'General.processing' });
    if (isStatusFailed) return intl.formatMessage({ id: 'General.failed' });
    if (isStatusVerified) return intl.formatMessage({ id: 'General.verified' });

    return intl.formatMessage({ id: 'General.unverified' });
  }, [intl, isStatusFailed, isStatusProcessing, isStatusVerified]);

  useEffect(() => {
    if (!currentUser?.id) return;

    setBusinessIdStatus(getUserBusinessIDStatus(currentUser));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id?.uuid]);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  return (
    <div className={classes}>
      {label ? (
        <div>
          <label htmlFor={`${id}.number`} className={css.label}>
            {label}
          </label>
          <div className={css.desc}>
            {intl.formatMessage(
              { id: 'General.businessIdDesc' },
              {
                help: (
                  <ExternalLink href="https://spxgc.tawk.help/article/business-taxid">
                    {intl.formatMessage({ id: 'General.help' }).toLowerCase()}
                  </ExternalLink>
                ),
              }
            )}
          </div>
        </div>
      ) : null}
      <BusinessIDInput {...inputProps} />

      <div className={css.status}>
        {`${intl.formatMessage({ id: 'General.status' })}: `}
        <span
          className={classNames(css.statusFailed, {
            [css.statusProcessing]: isStatusProcessing,
            [css.statusVerified]: isStatusVerified,
            [css.statusFailed]: isStatusFailed,
          })}
        >
          {status.toLowerCase()}
        </span>
      </div>

      {createStripeCustomerError && (
        <ValidationError fieldMeta={{ touched, error: createStripeCustomerError.message }} />
      )}
      {hideErrorMessage ? null : <ValidationError fieldMeta={meta} />}
    </div>
  );
};

FieldBusinessIDInputComponent.defaultProps = {
  className: null,
  id: null,
  label: null,
  hideErrorMessage: false,
};

FieldBusinessIDInputComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  hideErrorMessage: bool,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldBusinessIDInput = props => {
  const intl = useIntl();

  return (
    <Field
      component={FieldBusinessIDInputComponent}
      {...props}
      intl={intl}
      validate={composeValidators(
        props.validate || (() => {}),
        // We need this to send meta to final-form,
        // because we are checking for Tax validity on blur by API.
        checkTaxObjectValidity(intl.formatMessage({ id: 'General.invalidID' }))
      )}
    />
  );
};

export default FieldBusinessIDInput;
